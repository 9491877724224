export default {
  footer: {
    background:
      'linear-gradient(180deg,rgb(255 255 255 / 84%) 0%,rgb(164 188 224) 100%)',

    '.logo': {
      maxHeight: '75px',
    },
    '.multiButtonContainer': {
      marginBottom: '1rem',
      border: 'none',
      a: {
        borderRadius: '20px',
        border: 'none !important',
        background: '#292f7530',
        margin: '0.5rem',
        color: 'white',
      },
    },
  },

  contactForm: {
    order: '4',
    backgroundColor: '#f5f5f5',
    color: 'black',
    h2: {
      textTransform: 'uppercase',
      color: 'black',
      borderBottom: '2px white solid',
    },
    '.subtitle': {
      fontSize: '1.25rem',
      opacity: '0.7',
      color: 'black',
    },
    backgroundPosition: 'center center',
    padding: ['3.5rem 1rem', '', '5rem 1rem'],
    '.inputField, .textField ': {
      margin: '1rem 0rem',
      fontWeight: '300',
      border: 'none',
      borderBottom: '2px solid',
      borderColor: 'black',
      borderWidth: '1px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'black',
      '::placeholder': {
        color: 'black',
        opacity: '0.9',
      },
    },
    '.submitBtn': {
      variant: 'buttons.secondary',
      marginTop: '1rem',
      background: 'black',
      ':hover': {
        borderColor: 'white',
        color: 'white',
      },
    },
  },

  // ? ==========================
  // ? ======  Homepage  ========
  // ? ==========================

  homepageHero: {
    textTransform: 'uppercase',
    textAlign: 'center',
    color: 'light',
    height: '75vh',

    '.slick-initialized .slick-slide > div': {
      height: '75vh',
    },

    '.hero_content_container': {
      left: '50%',
      marginLeft: '0rem',
      transform: 'translate(-50%,-50%)',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      padding: '2rem',
      maxWidth: '767px',
    },
    '.title': { fontSize: ['3rem', '', '4rem', '5rem', '6rem'] },
    '.subtitle': { fontSize: ['1.1rem', '', '1.25rem', '1.75rem'] },
    '.ctaButton': {
      variant: 'buttons.secondary',
      marginBottom: '0rem',
    },

    '.slick-slide img': {
      filter: 'brightness(0.6)',
      height: '75vh',
    },
    'svg.slick-arrow': {
      color: 'white',
    },
  },

  homepageHeroShout: {
    display: 'none',
  },

  shoutSection: {
    padding: '3rem 0rem',
    maxHeight: ['', '', '60vh'],
    '.title': {
      color: 'primary',
      fontSize: ['1.25rem', '', '1.5rem', '1.75rem'],
    },
    '.image': {
      borderRadius: '0px 100px 400px 0px',
      paddingRight: '1rem',
    },

    '.shoutCTA': {
      variant: 'buttons.primary',
    },
    '.date': {
      opacity: '0.7',
    },

    '.text': {
      marginBottom: '1rem',
      fontWeight: 'bold',
      fontSize: ['1.75rem', '', '2rem', '2.5rem'],
      maxWidth: 'unset',
    },
  },

  homepageSlider: {
    padding: '4rem 0rem 2rem',
    '.mainTextContainer': {
      '.title': {
        fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
        color: 'primary',
      },
      '.subtitle': {
        fontSize: ['1.5rem', '1.75rem', '2rem', '2.5rem'],
        opacity: '0.7',
      },
    },
    '.slick-slide img': {
      maxWidth: '150px',
      maxHeight: '150px',
      opacity: '0.7',
    },
    '.imageFill': {
      paddingBottom: '75%',
    },
  },

  sideBySide1: {
    '.image': {
      maxHeight: ['55vh'],
    },
  },

  sideBySide2: {
    '.image': {
      maxHeight: ['55vh'],
    },
  },

  homepageContentBoxes: {
    '.image': {
      height: ['250px', '', '350px'],
      objectFit: 'cover',
    },
    '.title': {
      fontWeight: '500',
      marginBottom: '1rem',
      fontSize: '1.5rem',
      textTransform: 'uppercase',
    },
    '.box': {},
  },

  homepageContentSection: {
    marginTop: '2rem',
    background:
      "linear-gradient( rgb(0 0 0 / 0%),rgb(0 0 0 / 20%) ),url('https://res.cloudinary.com/gonation/image/upload/v1612986621/sites/pools-plus/Inground_Liner_Install.jpg')",
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    color: 'white',
    padding: ['3rem 1rem', '', '8rem 1rem'],
    '.title': {
      fontSize: ['', '', '4.5rem'],
      borderTop: '2px solid',
      borderBottom: '2px solid white',
      padding: '2rem 1rem',
    },
  },
}
